import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarComponent } from "../component/other/snackbar/snackbar.component";
import { ApiErrorPipe } from "../shared/pipes/api-error.pipe";
import { BehaviorSubject, first } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  message: string;
  messagesQueue: MatSnackBarConfig<any>[] = [];
  messagesQueue$: BehaviorSubject<MatSnackBarConfig<any>[]> = new BehaviorSubject([]);
  snackBarActive: boolean = false;

  constructor(
    private snackbar: MatSnackBar,
    /**
     * CIRCULAR DEPENDENCY HERE
     *
     * @TODO remove the TranslateService from here, pass the texts already translated
     *
     */
    private translateService: TranslateService
  ) {
    this.messagesQueue$.subscribe(data => {
      if (!data.length || this.snackBarActive) {
        return;
      }
      this.snackBarActive = true;

      this.snackbar.openFromComponent(
        SnackbarComponent,
        { ...data[0] },
      ).afterDismissed().pipe(first()).subscribe(() => {
        this.messagesQueue = data.slice(1);
        this.snackBarActive = false;
        this.messagesQueue$.next(this.messagesQueue);
      })
    })
  }

  /** @deprecated use success(), error() or info()  */
  show(name = this.message, success = true, param?: object, content = true, transale = true) {
    if (!name) {
      return;
    }
    const subtitle = content ? 'CONTENT' : 'NOT_INTEGRATED';
    let data;
    if(transale){
      data = {
        title: this.translateService.instant(`MESSAGE.${name}.TITLE`, param),
        content: this.translateService.instant(`MESSAGE.${name}.${subtitle}`, param),
        success: success
      }
    }
    else{
      data = {
          title: `Erro`,
          param,
          content: `${name}`,
          success: false
      }
    }
    this.messagesQueue.push({
      data: data,
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: success ? 'snackbar-success' : 'snackbar-error',
      verticalPosition: 'top'
    })

    this.messagesQueue$.next(this.messagesQueue);

    this.message = '';
  }

  /**
   * @param message The message to be translated
   * @param param The param for translation, if needed
   */
  success(message: string, param?: object) {
    const translatedTitle: string = this.translateService.instant(`MESSAGE.${message}.TITLE`, param);
    const translatedContent: string = this.translateService.instant(`MESSAGE.${message}.CONTENT`, param);
    const title = translatedTitle.startsWith("MESSAGE.") ? "" : translatedTitle;
    const content = translatedContent.startsWith("MESSAGE.") ? message : translatedContent;
    this.messagesQueue.push({
      data: {
        title,
        content,
        success: true
      },
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'snackbar-success',
      verticalPosition: 'top'
    });

    this.messagesQueue$.next(this.messagesQueue);
  }

  /**
   * 
   * @param message The message to be translated
   * @param param The param for translation, if needed
   * @param error The error caught
   */
  error(message: string, param?: object, error?: any) {
    let errorDescription;
    if(error){
      const errorKey = 'ELEMENT.ERROR.' + new ApiErrorPipe().transform(error)
      errorDescription = this.translateService.instant(errorKey);
    }

    const translatedTitle: string = this.translateService.instant(`MESSAGE.${message}.TITLE`, param);
    const translatedContent: string = this.translateService.instant(`MESSAGE.${message}.CONTENT`, param);
    const title = translatedTitle.startsWith("MESSAGE.") ? "" : translatedTitle;
    const content = translatedContent.startsWith("MESSAGE.") ? message : translatedContent;

    this.messagesQueue.push({
      data: {
        title,
        content,
        success: false,
        error: errorDescription
      },
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'snackbar-error',
      verticalPosition: 'top'
    });

    this.messagesQueue$.next(this.messagesQueue);
  }

  info(message: string, param?: object, duration = 5000) {
    const translatedTitle: string = this.translateService.instant(`MESSAGE.${message}.TITLE`, param);
    const translatedContent: string = this.translateService.instant(`MESSAGE.${message}.CONTENT`, param);
    const title = translatedTitle.startsWith("MESSAGE.") ? "" : translatedTitle;
    const content = translatedContent.startsWith("MESSAGE.") ? message : translatedContent;

    this.messagesQueue.push({
      data: {
        title: this.translateService.instant(`MESSAGE.${message.toUpperCase()}.TITLE`, param),
        // title: message,// title
        content: this.translateService.instant(`MESSAGE.${message.toUpperCase()}.CONTENT`, param),
        // content: message // content
      },
      duration: duration,
      horizontalPosition: 'right',
      panelClass: 'snackbar-info',
      verticalPosition: 'top'
    });

    this.messagesQueue$.next(this.messagesQueue);
  }
}
